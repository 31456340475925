import { useState } from "react";

import { sendContactUsMessage } from "../../../api/contact";
import Button from "./utils/Button";

type ContactUsProps = {
  inverseColors?: boolean;
  onSuccess: any;
  onFail: any;
};

function ContactUs({ inverseColors, onSuccess, onFail }: ContactUsProps) {
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();

    setIsLoading(true);

    sendContactUsMessage(email, company, message)
      .then(() => {
        setEmail("");
        setCompany("");
        setMessage("");

        onSuccess();
      })
      .catch(() => {
        onFail();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function getTextStyle() {
    if (inverseColors) {
      return "text-gray-100";
    } else {
      return "text-gray-800";
    }
  }

  return (
    <>
      <div className='text-center'>
        <h2 className={`h2 ${getTextStyle()}`}>Contact us</h2>
      </div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className='flex flex-wrap mt-8'>
          <div className='w-full'>
            <label
              className={`block ${getTextStyle()} text-sm font-medium mb-1`}
            >
              Email <span className='text-red-600'>*</span>
            </label>
            <input
              id='email'
              type='email'
              className='form-input w-full text-gray-800'
              placeholder='Enter your email address'
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className='w-full mt-4'>
            <label
              className={`block ${getTextStyle()} text-sm font-medium mb-1`}
            >
              Company name / website
            </label>
            <input
              id='company'
              type='text'
              className='form-input w-full text-gray-800'
              placeholder='Enter your company name'
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
          </div>
          <div className='w-full mt-4'>
            <label
              className={`block ${getTextStyle()} text-sm font-medium mb-1`}
            >
              What can we help you with? <span className='text-red-600'>*</span>
            </label>
            <textarea
              id='message'
              rows={5}
              className='form-input w-full text-gray-800'
              placeholder='Enter your message'
              required
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
        </div>

        <div className='w-full mt-6'>
          <Button
            loading={isLoading}
            type='submit'
            text='Submit'
            size='large'
            width='max'
            color='secondary'
          />
        </div>
      </form>
    </>
  );
}

export default ContactUs;
