import { makeRequestAndReturnJSON } from "./common";
import { MessageResponse } from "./types";

export const sendContactUsMessage: (
  email: string,
  company: string,
  message: string
) => Promise<MessageResponse> = async (email, company, message) => {
  return makeRequestAndReturnJSON({
    method: "POST",
    url: `/api/app/contact`,
    body: JSON.stringify({ email, company, message }),
  });
};
